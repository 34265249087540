import { Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './assets/scss/DefaultTheme.scss';
import { MemberPermissionsProvider } from './components/member-permissions/context';
import { MembershipImpersonationProvider, ClearPersistedStorage } from './components/MembershipImpersonation/MembershipImpersonationContext';
import { MembershipInfoProvider } from './components/MembershipInfo/MembershipInfoContext';
import settings from './config';
import { configureStore } from './redux/store';
import { RouteProvider } from './routes';
import Router, { history } from './routes/Router';
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from './components/ApplicationInsights';

const onRedirectCallback = appState => {
    ClearPersistedStorage();
    history.push(
        appState 
        && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
  );
};

const providerWrappers: ((children: React.ReactNode) => JSX.Element)[] = [
    children => <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>,
    children => (
        <Auth0Provider
            domain={settings.AUTH0.DOMAIN}
            clientId={settings.AUTH0.CLIENT_ID}
            audience={settings.AUTH0.AUDIENCE}
            redirectUri={`${window.location.origin}/redirect`}
            onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
        >{children}</Auth0Provider>
    ),
    children => <MemberPermissionsProvider>{children}</MemberPermissionsProvider>,
    children => <RouteProvider>{children}</RouteProvider>,
    children => <MembershipImpersonationProvider>{children}</MembershipImpersonationProvider>,
    children => <MembershipInfoProvider>{children}</MembershipInfoProvider>,
    children => <Provider store={configureStore()}>{children}</Provider>
]

/**
 * Main app component
 */
const App = () => (
  providerWrappers
    .reverse()
    .reduce(
        (current, providerWrapper) => providerWrapper(current),
        <>
            <ToastContainer/>
            <Router/>
        </>)
);

export default App;