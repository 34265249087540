import {DateTimeString, GuidString} from "../../models/BrandedTypes";

export type MemberDetailsInfo = {
    id: string;
    balance: number;
    tradingName: string;
    companyType: CompanyType;
    companyNumber: string;
    mailingAddress: Address;
    invoiceAddress: Address;
    socialMediaLinks: SocialMediaLinks;
    primaryContact: Contact;
    additionalContacts: Contact[];
    latestMembership: Membership;
    currentProduct: CurrentProduct;
    mainPhone: string;
    titleSelectList: Title[];
    companyTypeId: number;
    companyRegistrationOrCharityNumber: string;
    referralCode: string;
    gasEngineer: GasEngineer;
    vatRegistered: boolean;
}

export type PartnerVoucher = {
    barcodeNumber: string,
    value: number,
    expiry: string,
    bookerCustomerNumber: string
}

export type MissingCompanyDetails = {
    companyTypeId: number
    companyRegistrationOrCharityNumber: string;
    vatRegistered: boolean;
}

export type PaymentType = {
    isCardPayment: boolean,
    name: string,
    id: GuidString
}

export type Title = {
    text: string;
    id: number;
}

export type CompanyDetails = {
    mailingAddress: Address;
    primaryContactId: number;
    tradingName: string;
    website: string;
    twitter: string;
    instagram: string;
    facebook: string;
    mainPhone: string;
    balance: number;
    membershipNumber: string;
    dateStartedTrading: Date;
    yearsTrading: number;
    numberOfTradingUnits: number;
    companyType: CompanyType;
    companyRegistrationorCharityNumber: string;
    name: string;
    id: string;
}

export type CompanyType = {
    id: number;
    name: string;
}

export enum CompanyTypeOption {
    SoleTrader = "Sole Trader",
    LimitedCompany = "Limited Company",
    PLC = "PLC",
    Charity = "Charity",
    LLP = "LLP",
    BusinessNotRegistered = "Business not registered",
    Partnerships = "Partnerships"
}

export type CompanyTypeData = {
    id: number;
    text: string;
}

export type Contact = {
    businessPhone: string;
    doNotAllowEmails: boolean;
    doNotAllowPhoneCalls: boolean
    email: string;
    firstName: string;
    fullName: string;
    homePhone: string;
    id: string;
    lastName: string;
    middleName: string;
    mobilePhone: string;
    salutation: string;
    titleAsString: string
    isNew: Boolean
}

export type Address = {
    line1: string;
    town: string;
    postCode: string;
    shortAddress: string;
    line2: string;
    county: string
}

type Membership = {
    name: string;
    automaticallyRenew: boolean;
    endDate: DateTimeString;
    startDate: DateTimeString;
    status: string;
    membershipSubType: string;
    paymentType: PaymentType;
    productName: string;
    intentionToRenew?: boolean;
}

export type SocialMediaLinks = {
    twitter: string;
    instagram: string;
    facebook: string;
    website: string;
}

export type CurrentProduct = {
    id: string,
    membershipType: string,
    name: string,
    productCode: string
}

export enum MembershipTier {
    Standard = 1,
    Premium = 2,
    PremiumPlus = 3,
    Pro = 4
}

export type MembershipBenefits = {
    StandardBenefits: Benefit[];
}

export type Benefit = {
    name: string,
    namePlural: string,
    quantity: number,
    currentQty?: number,
    discount: string
}

export type GasEngineer = {
    id: string;
    name: string;
    mainPhone: string;
    website: string;
}