import { RouteConfig } from "../../types/RouteConfig";
import { biopakRoutes } from "./biopak/routes";
import { bookerRoutes } from "./booker/routes";
import { calorRoutes } from "./calor/routes";
import { rewardsHomeRoute } from "./home/route";
import { nisbetsRoutes } from "./nisbets/routes";
import { vouchersRoutes } from "./vouchers/routes"

export const rewardsRoutes: RouteConfig<'with-children'> = {
    name: 'Rewards',
    icon: 'mdi mdi-trophy-variant-outline',
    children: [
        rewardsHomeRoute,
        vouchersRoutes,
        biopakRoutes,
        bookerRoutes,
        calorRoutes,
        nisbetsRoutes,
    ],
}